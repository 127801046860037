import Button from 'reactstrap/lib/Button';
import * as Sentry from '@sentry/react';

const SentryErrorBoundary = props => (
    <Sentry.ErrorBoundary
        fallback={({ componentStack, error, resetError }) => (
            <div style={{ padding: '32px' }}>
                <h2>Hmm...Something went wrong.</h2>
                <div>
                    <div>To get back where you left off, click our reset button!</div>
                    <br />
                    <div>
                        <Button color="success" onClick={resetError}>
                            Take me back!
                        </Button>
                    </div>
                </div>
                <div style={{ margin: '4em 0 1em 0' }}>
                    <em>Our developers have been automatically notified and will resolve this issue as soon as possible. </em>
                </div>
                <details open style={{ whiteSpace: 'pre-wrap', color: 'red' }}>
                    <summary>❌ {error && error.toString()}</summary>
                    {componentStack}
                </details>
            </div>
        )}
        showDialog
    >
        {props.children}
    </Sentry.ErrorBoundary>
);

export default SentryErrorBoundary;
