class Enumeration {
    list = {};

    constructor(list) {
        list.forEach(item => {
            let property;

            if (item.key) {
                property = item.key;
            } else {
                property = item.name.replace(/\s/g, '');
            }

            this[property] = item.id;
            this.list[item.id] = item;
        });

        return Object.freeze(this);
    }

    getName = (index = null) => {
        if ((index != 0 && !index) || !this.list[index] || !this.list[index].name) {
            return;
        }

        return this.list[index].name;
    };

    getLabel = (index = null) => {
        if ((index != 0 && !index) || !this.list[index] || !this.list[index].name) {
            return;
        }

        return this.list[index].label;
    };

    getPathName = (index = null) => {
        if (!index || !this.list[index] || !this.list[index].pathName) {
            return;
        }

        return this.list[index].pathName;
    };

    getOptions = (config = {}) => {
        const options = [];

        if (config.spread) {
            Object.values(this.list).forEach(value => {
                options.push({ ...value, label: value.name, value: value.id });
            });
        } else if (config.categories) {
            config.categories.forEach(category => {
                options.push({
                    label: category,
                    options: Object.values(this.list)
                        .filter(value => value.category == category)
                        .map(value => ({ label: config.abbreviate ? value.abbreviation : value.name, value: value.id }))
                });
            });
        } else {
            Object.values(this.list).forEach(value => {
                options.push({ label: value.name, value: value.id });
            });
        }

        if (config.sort) {
            return options.sort((a, b) => {
                const fa = a.label.toLowerCase();
                const fb = b.label.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
        }

        return options;
    };

    getMap = property => {
        const map = [];

        if (property) {
            Object.keys(this.list).forEach(key => {
                map[key] = this.list[key][property];
            });

            return map;
        }

        Object.keys(this.list).forEach(key => {
            map[key] = this.list[key].name;
        });

        return map;
    };

    getDictionary = () => {
        const map = [];

        Object.keys(this.list).forEach(key => {
            map[key] = { id: key, name: this.list[key].name };
        });

        return map;
    };

    getProperty = (index, property, defaultValue = null) => {
        if (!this.list[index]) {
            return defaultValue;
        }

        if (!this.list[index][property]) {
            return defaultValue;
        }

        return this.list[index][property];
    };
}

export default Enumeration;
