import { useSelector } from 'react-redux';
import { Box } from '@mui/system';

import PaperContainer from 'Lib/components/layout/components/PaperContainer';

export default function NotFound() {
    const menuVisible = useSelector(state => state?.ui?.menuVisible);

    return (
        <div id="default-layout">
            <div className="main">
                <PaperContainer open={menuVisible}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', pt: 8 }}>
                        <h3>Sorry, this page does not exist. Please try navigating elsewhere</h3>
                    </Box>
                </PaperContainer>
            </div>
        </div>
    );
}
