/** AUTHENTICATION  */
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';

/** APPLICATION **/
export const APPLICATION_LOAD = 'APPLICATION_LOAD';
export const APPLICATION_LOAD_FAILED = 'APPLICATION_LOAD_FAILED';
export const APPLICATION_UNLOAD = 'APPLICATION_UNLOAD';

export const GENERAL_LEDGER_ACCOUNTS_RELOAD = 'GENERAL_LEDGER_ACCOUNTS_RELOAD';
export const GENERAL_LEDGER_ACCOUNTS_RELOAD_FAILED = 'GENERAL_LEDGER_ACCOUNTS_RELOAD_FAILED';

export const ACCOUNTS_RELOAD = 'ACCOUNTS_RELOAD';
export const ACCOUNTS_RELOAD_FAILED = 'ACCOUNTS_RELOAD_FAILED';

export const ACCOUNTING_SETTINGS_RELOAD = 'ACCOUNTING_SETTINGS_RELOAD';
export const ACCOUNTING_SETTINGS_RELOAD_FAILED = 'ACCOUNTING_SETTINGS_RELOAD_FAILED';

/** ASYNCH  */
export const ASYNCH_START = 'ASYNCH_START';
export const ASYNCH_END = 'ASYNCH_END';

export const CACHE_ENTITY = 'CACHE_ENTITY';
export const REMOVE_CACHE_ENTITY = 'REMOVE_CACHE_ENTITY';

/** MENU **/
export const MENU_ITEM_SELECTED = 'MENU_ITEM_SELECTED';

/** NOTIFY **/
export const NOTIFY = 'NOTIFY';

export const API_REQUEST = 'API_REQUEST';

/** HISTORY **/
export const SET_HISTORY = 'SET_HISTORY';
