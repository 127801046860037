/**
 * screenSize - A custom React Hook for determining screen size.
 *
 * @returns {Object} `{ smallMobile: boolean, mobile: boolean, tablet: boolean }`
 *
 * Usage:
 * const { smallMobile, mobile, tablet } = screenSize();
 */

import { useMediaQuery } from '@mui/material';

export function screenSize() {
    const smallMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const tablet = useMediaQuery(theme => theme.breakpoints.between('sm', 'md'));

    return { smallMobile, mobile, tablet };
}
