import Enumeration from 'Lib/utilities/Enumeration';

/* If changing an id, update value in webpack.common in each portal. */
const PortalTypes = new Enumeration([
    { id: 1, name: 'Manager' },
    { id: 2, name: 'Owner' },
    { id: 3, name: 'Tenant' },
    { id: 4, name: 'Vendor' },
    { id: 5, name: 'Association' },
    { id: 6, name: 'Applicant' },
    { id: 7, name: 'Admin' },
    { id: 8, name: 'Public' }
]);

export default PortalTypes;
