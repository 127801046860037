// @ts-check

import { useSelector } from 'react-redux';
import axios from 'axios';

import useConfig from './useConfig';
import { useSession } from './useSession';

export default function useAgent() {
    const app = useSelector(state => state.app);
    const { apiRoot } = useConfig();
    const { logout } = useSession();

    const portfolioID = app.portfolio?.portfolioID || null;
    const leaseID = app.lease?.lease?.leaseID || null;
    const portalID = app.portal?.owner?.contactID || app.portal?.vendor?.contactID || app.portal?.tenant?.contactID || null;

    const axiosInstance = axios.create({
        baseURL: apiRoot,
        headers: {
            'RV-Portfolio': portfolioID,
            'RV-Lease': leaseID,
            'RV-Portal': portalID
        }
    });

    axiosInstance.interceptors.response.use(
        response => response,
        error => {
            if (!error.config.url.includes('init') && (error.message.includes('401') || error.response?.status === 401)) {
                logout();

                return Promise.reject(error);
            }

            return Promise.reject(error);
        }
    );

    const executeRequest = (/** @type {string} */ method, /** @type {string | undefined} */ url, data = null, params = {}, config = {}, signal) => {
        return axiosInstance({ method, url, data, params, ...config, signal })
            .then(response => response)
            .catch(error => {
                throw error;
            });
    };

    const methods = {
        get: (/** @type {string | undefined} */ url, /** @type {any} */ params, /** @type {any} */ data, signal) => executeRequest('GET', url, data, params, signal),
        post: (/** @type {string | undefined} */ url, /** @type {any} */ data, /** @type {any} */ params, config, method = 'POST') =>
            executeRequest(method, url, data, params, config),
        del: (/** @type {string | undefined} */ url, /** @type {any} */ params, /** @type {any} */ data) => executeRequest('DELETE', url, data, params),
        upload: (
            /** @type {string | undefined} */ url,
            /** @type {{}} */ data,
            /** @type {string | Blob} */ file,
            /** @type {{} | undefined} */ params,
            config = { headers: { 'Content-Type': 'multipart/form-data' } }
        ) => {
            const safeData = data ?? {};
            const formData = new FormData();

            Object.keys(safeData).forEach(key => formData.append(key, safeData[key]));
            formData.append('file', file);

            return executeRequest('POST', url, formData, params, config);
        },
        download: (/** @type {string | undefined} */ url, /** @type {{} | undefined} */ params, /** @type {null | undefined} */ data, method = 'GET') =>
            executeRequest(method, url, data, params, { responseType: 'blob' }),
        downloadPost: (/** @type {string | undefined} */ url, /** @type {null | undefined} */ data, /** @type {{} | undefined} */ params) =>
            executeRequest('POST', url, data, params, { responseType: 'blob' })
    };

    return { ...methods, portalID, portfolioID, leaseID };
}
