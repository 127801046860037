import Loadable from 'react-loadable';

import isFunction from 'Lib/utilities/isFunction';

/**
 * You're probably loooking for lib/node/src/components/common/Loading
 * This Loading component is only used for react-loadable and routes
 */
export const Loading = () => <div data-cy="loader" />;

export const Loader = (obj, props) => {
    let loader, loading;

    if (isFunction(obj)) {
        loader = obj;
        loading = Loading;
    } else if (obj.loader && obj.loading) {
        loader = obj.loader;
        loading = obj.loading;
    } else {
        loader = obj.loader;
        loading = Loading;
    }

    return Loadable({
        loader,
        loading,
        render: (loadedComponent, loadableProps) => {
            const Component = loadedComponent.default;

            return <Component {...props} {...loadableProps} />;
        }
    });
};

export default Loader;
