import moment from 'moment';

export const isDate = date => {
    return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
};

export const now = () => {
    return moment(new Date());
};

export const today = (format = 'YYYY-MM-DD') => {
    return moment.utc(new Date()).local().format(format);
};
