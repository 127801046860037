// @ts-check

import { createContext, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';

import useNotify from '../useNotify';

import useAgent from './useAgent';

/**
 * @typedef {Object} UploadMutationProps
 * @property {FileWithPath} file
 * @property {Object[]} [data]
 * @property {string} id
 */

/**
 * @param {import("@tanstack/react-query").QueryKey} mutationKey
 * @param {string | null} [scope]
 */
export function useUploadFile(mutationKey, scope = null) {
    const { toastError } = useNotify();

    const { dispatch } = useUploadContext();

    const { post } = useAgent();

    return useMutation({
        mutationKey: mutationKey,
        onMutate: (/** @type {UploadMutationProps} */ { file, data, id }) => {
            // add the file to the uploads store
            dispatch({ type: 'ADD_UPLOAD', payload: { id, mutationKey, scope, status: 'pending', progress: 0, file } });
        },
        mutationFn: (/** @type {UploadMutationProps} */ { file, data, id }) => {
            // mutationKey is an array of strings and objects. The first string is the URL, the first object is the params, the last object is the body.
            const params = mutationKey.filter(key => typeof key === 'object')[0];
            const url = mutationKey.filter(key => typeof key === 'string').join('/');

            const formData = new FormData();
            // map every key value pair to the form data
            data &&
                data.forEach(item => {
                    Object.entries(item).forEach(([key, value]) => {
                        formData.append(key, value);
                    });
                });
            formData.append('file', file);

            return post(url, formData, params, {
                onUploadProgress: (/** @type {{ loaded: number; total: number; }} */ progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 90) / progressEvent.total);
                    // skip setting the progress event unless the value is a multiple of 10
                    dispatch({ type: 'UPDATE_UPLOAD', payload: { id, progress: percentCompleted } });
                }
            });
        },
        onError: (error, { id }) => {
            toastError(error.message);
            dispatch({ type: 'UPDATE_UPLOAD', payload: { id, status: 'error' } });
        },
        onSuccess: (data, { id }) => {
            dispatch({ type: 'UPDATE_UPLOAD', payload: { id, status: 'success', progress: 100, data } });
        }
    });
}

/**
 * @typedef {Object} UploadAction
 * @property {string} type
 * @property {Upload} payload
 */

/**
 * @param {Upload[]} state
 * @param {UploadAction} action
 * @returns {Upload[]}
 */

export function uploadReducer(state, action) {
    switch (action.type) {
        case 'ADD_UPLOAD':
            return [...state, action.payload];
        case 'UPDATE_UPLOAD':
            return state.map(upload => (upload.id === action.payload.id ? { ...upload, ...action.payload } : upload));
        case 'REMOVE_UPLOAD':
            return state.filter(upload => upload.id !== action.payload.id);
        case 'REMOVE_UPLOADS':
            return state.filter(upload => upload.scope !== action.payload.scope);
        default:
            return state;
    }
}

/**
 * @type {never[]}
 */
export const initialState = [];

/**
 * @type {React.Context<{uploads: Upload[]; dispatch: (action: UploadAction) => void; }>}
 */
export const UploadsContext = createContext({
    uploads: [],
    dispatch: ({ type = '', payload = {} }) => {}
});

export const useUploadContext = () => {
    const { uploads, dispatch } = useContext(UploadsContext);

    return { uploads, dispatch };
};

/**
 * @param {string | null} scope
 */
export const useUploads = (scope = null) => {
    const { uploads, dispatch } = useUploadContext();

    const filteredUploads = scope ? uploads.filter(upload => upload.scope === scope) : uploads;

    return {
        uploads: filteredUploads,
        pendingUploads: filteredUploads.filter(upload => upload.status === 'pending'),
        isUploading: filteredUploads.some(upload => upload.status === 'pending'),
        completeUploads: filteredUploads.filter(upload => upload.status === 'success'),
        failedUploads: filteredUploads.filter(upload => upload.status === 'error'),
        allComplete: filteredUploads.every(upload => upload.status === 'success') && filteredUploads.length > 0,
        removeUploads: () => dispatch({ type: 'REMOVE_UPLOADS', payload: { scope } }),
        removeUpload: (/** @type {string} */ id) => dispatch({ type: 'REMOVE_UPLOAD', payload: { id } })
    };
};

export const useUpload = (/** @type {string} */ id) => {
    const { uploads, dispatch } = useUploadContext();

    const upload = uploads.find(upload => upload.id === id);

    return {
        upload,
        isUploading: upload?.status === 'pending',
        isComplete: upload?.status === 'success',
        isFailed: upload?.status === 'error',
        progress: upload?.progress,
        file: upload?.file,
        data: upload?.data,
        status: upload?.status,
        removeUpload: () => dispatch({ type: 'REMOVE_UPLOAD', payload: { id } }),
        updateUpload: (/** @type {Upload} */ payload) => dispatch({ type: 'UPDATE_UPLOAD', payload })
    };
};
