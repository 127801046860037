import { Alert } from '@mui/material';

export default function Impersonate({ open, severity = 'warning' }) {
    return (
        open && (
            <Alert severity={severity} sx={{ borderRadius: 2, position: 'sticky', top: 0, zIndex: 10 }}>
                You are currently in impersonation mode and have limited access to certain actions
            </Alert>
        )
    );
}
