import getErrorMessages from 'Lib/utilities/getErrorMessages';
import { removeDuplicates } from 'Lib/utilities/removeDuplicates';

const ErrorReasons = ({ errors }) =>
    errors.map((error, index) => (
        <ul key={index}>
            <li>{error}</li>
        </ul>
    ));

export default function extractErrors(toast, error) {
    let errorResponse = error.response ? error.response.data : error ?? {};
    const mappedErrors = [];

    if (!errorResponse) {
        errorResponse = {};
    }

    Object.values(errorResponse).map(value => {
        if (value?.adapter || value?.onreadystatechange || typeof value == 'function' || typeof value == 'boolean') {
            return;
        } else {
            if (Array.isArray(value)) {
                value.forEach(v => mappedErrors.push(getErrorMessages(v)));
            } else if (value?.data && typeof value?.data === 'object') {
                mappedErrors.push(getErrorMessages(value.data));
            } else if (typeof value === 'object') {
                mappedErrors.push(getErrorMessages(value));
            } else {
                mappedErrors.push(value);
            }
        }
    });

    toast.error(<ErrorReasons errors={removeDuplicates(mappedErrors.flat())} />);
}
