// @ts-check

import { useDispatch } from 'react-redux';
import axios from 'axios';
import queryString from 'query-string';

import { now } from 'Lib/utilities/datetime';

import useConfig from './useConfig';

export const useSession = () => {
    const { loginUrl, portalTypeId, apiRoot, root_domain, client_id } = useConfig();
    const dispatch = useDispatch();

    const logout = () => userLogout(portalTypeId, apiRoot, loginUrl, dispatch, root_domain);
    const login = () => userLogin(loginUrl, client_id, root_domain);

    return { logout, login };
};

/**
 * @param {number} portalTypeId
 * @param {any} apiRoot
 * @param {string} loginUrl
 * @param {import("redux").Dispatch<import("redux").AnyAction>} dispatch
 * @param {string} root_domain
 * @returns {void}
 */
const userLogout = (portalTypeId, apiRoot, loginUrl, dispatch, root_domain) => {
    // OAuth Login
    const oauthQs = queryString.stringify({
        portalID: portalTypeId,
        subdomain: getSubdomain()
    });

    const revokeToken = () => axios.get(`${apiRoot}/oauth/revoke`);

    setLoginUrl(loginUrl);
    revokeToken()
        .then(() => {
            clearTimeout(300);
            // dispatch action to clear the session
            dispatch({
                type: 'session/UPDATE',
                isAuthenticated: false
            });
        })
        .finally(() => {
            window.location.assign(`https://account${root_domain}/oauth2/logout?${oauthQs}`);
        });

    return;
};

function getSubdomain() {
    // window.location.host is subdomain.domain.com
    const full = window.location.host;
    const parts = full.split('.');

    return parts[0];
}

/**
 * @param {string} loginUrl
 */
function setLoginUrl(loginUrl) {
    // Omitting redirect as it's strictly used for the login action below to set the login URL in certain instances
    const { redirect, ...qs } = queryString.parse(window.location.search);
    const path = JSON.stringify({
        pathname: window.location.pathname,
        search: queryString.stringify(qs),
        logoutDateTime: now()
    });

    window.localStorage.setItem(loginUrl, path);
}

/**
 * @param {string} loginUrl
 * @param {string} client_id
 * @param {string} root_domain
 */
function userLogin(loginUrl, client_id, root_domain) {
    const { redirect } = queryString.parse(window.location.search);
    // If redirect is present, then we need to set the login url
    if (redirect) setLoginUrl(loginUrl);
    // OAuth Login
    const oauthQs = queryString.stringify({
        client_id: client_id,
        response_type: 'code',
        subdomain: getSubdomain()
    });
    window.location.assign(`https://account${root_domain}/oauth2/authorize?${oauthQs}`);

    return;
}
