import { Component } from 'react';

class Notify extends Component {
    renderMessages = () => {
        const { messages } = this.props;

        if (!Array.isArray(messages)) return null;

        return (
            <ul className="message-list">
                {messages.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        );
    };

    render() {
        return (
            <div className="notify">
                {this.props.message}
                {this.renderMessages()}
            </div>
        );
    }
}

export default Notify;
