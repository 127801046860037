const getErrorMessages = (errors, messages = []) => {
    for (var key in errors) {
        if (typeof errors === 'string') messages.push(errors);
        else if (errors[key] !== null && typeof errors[key] == 'object') getErrorMessages(errors[key], messages);
        else if (errors[key] !== null && typeof errors[key] == 'string') messages.push(errors[key]);
        else messages.push(errors);
    }

    return messages;
};

export default getErrorMessages;
