import axios from 'axios';

import PortalTypes from 'Lib/constants/app/portalTypes';

export const root_domain = process.env.DOMAIN;

export const getApiRoot = portalType => {
    const apiRoots = {
        [PortalTypes.Admin]: `https://admin${root_domain}/api/`,
        [PortalTypes.Manager]: `https://${window.location.host}/api/manager/`,
        [PortalTypes.Owner]: `https://${window.location.host}/api/owner/`,
        [PortalTypes.Public]: `https://${window.location.host}/api/public/`,
        [PortalTypes.Tenant]: `https://${window.location.host}/api/resident/`,
        [PortalTypes.Vendor]: `https://${window.location.host}/api/vendor/`,
        [PortalTypes.Applicant]: `https://${window.location.host}/api/applicant/`
    };

    return apiRoots[portalType] || '';
};

export const createAgent = (actions, store) => {
    const portalType = store.getState().app.portalTypeID;

    const axiosInstance = axios.create({ baseURL: getApiRoot(portalType) });

    axiosInstance.interceptors.response.use(
        response => response,
        async error => {
            if (!error?.config?.url?.includes('init') && (error?.message?.includes('401') || error?.response?.status === 401)) {
                if (store && actions) store.dispatch(actions.logout());

                return Promise.reject(error);
            }

            // Handle blobs (e.g., for file downloads) containing JSON error messages
            if (error.response && error.response.data instanceof Blob) {
                try {
                    const text = await error.response.data.text();
                    const json = JSON.parse(text);

                    return Promise.reject({ ...error, response: { ...error.response, data: json } });
                } catch {
                    return Promise.reject(error);
                }
            }

            return Promise.reject(error);
        }
    );

    const executeRequest = (method, url, data = null, params = {}, config = {}) => {
        const ownerContactID = store.getState().app.portal?.owner?.contactID;
        const vendorContactID = store.getState().app.portal?.vendor?.contactID;
        const tenantContactID = store.getState().app.portal?.tenant?.contactID;
        const applicantContactID = store.getState().app.portal?.applicant?.contactID;
        const portfolioID = store.getState().app.portfolio?.portfolioID;
        const leaseID = store.getState().app.lease?.lease?.leaseID;
        const headers = { ...config.headers };

        if (portfolioID) headers['RV-Portfolio'] = portfolioID;
        if (leaseID) headers['RV-Lease'] = leaseID;

        if (ownerContactID) headers['RV-Portal'] = ownerContactID;
        else if (vendorContactID) headers['RV-Portal'] = vendorContactID;
        else if (tenantContactID) headers['RV-Portal'] = tenantContactID;
        else if (applicantContactID) headers['RV-Portal'] = applicantContactID;

        const updatedConfig = { ...config, headers };

        return axiosInstance({ method, url, data, params, ...updatedConfig })
            .then(response => response)
            .catch(error => {
                throw error;
            });
    };

    const requestMethods = {
        get: (url, params, data) => executeRequest('GET', url, data, params),
        post: (url, data, params) => executeRequest('POST', url, data, params),
        put: (url, data, params) => executeRequest('PUT', url, data, params),
        del: (url, params, data) => executeRequest('DELETE', url, data, params),
        upload: (url, data, file, params, config = { headers: { 'Content-Type': 'multipart/form-data' } }) => {
            const safeData = data ?? {};
            const formData = new FormData();

            Object.keys(safeData).forEach(key => formData.append(key, safeData[key]));
            formData.append('file', file);

            return executeRequest('POST', url, formData, params, config);
        },
        download: (url, params, data, method = 'GET') => executeRequest(method, url, data, params, { responseType: 'blob' }),
        downloadPost: (url, data, params) => executeRequest('POST', url, data, params, { responseType: 'blob' })
    };

    const tokenMethods = {
        requestToken: params => executeRequest('GET', `${getApiRoot(portalType)}oauth/token`, null, params),
        refreshToken: () => executeRequest('GET', `${getApiRoot(portalType)}oauth/refresh`),
        revokeToken: () => executeRequest('GET', `${getApiRoot(portalType)}oauth/revoke`),
        logout: () => executeRequest('GET', `${getApiRoot(portalType)}logout`)
    };

    return { request: { ...requestMethods }, ...tokenMethods };
};

export default createAgent;
